import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid } from '@mui/material';
import config from '../../config';
import '../CSS/course.css';
import '../CSS/sidebarfilter.css';

const CourseLeftBar = ({ onFilterChange, openFilterbarToggle, OpenSideFilterbar }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedPricing, setSelectedPricing] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}courseCategory/`);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryChange = (event, categoryId) => {
    const updatedCategories = event.target.checked
      ? [...selectedCategories, categoryId]
      : selectedCategories.filter(id => id !== categoryId);

    setSelectedCategories(updatedCategories);
    onFilterChange(updatedCategories, selectedPricing);
  };

  const handlePricingChange = (event, pricingValue) => {
    const updatedPricing = event.target.checked
      ? [...selectedPricing, pricingValue]
      : selectedPricing.filter(value => value !== pricingValue);

    setSelectedPricing(updatedPricing);
    onFilterChange(selectedCategories, updatedPricing);
  };

  return (
    <aside id={openFilterbarToggle ? "course-filter-sidebar":""} className={openFilterbarToggle ? "course-filter-sidebar-responsive": "course-leftbar"}>
      {/* <div className='sidebar-title'>
        <span className='userdashboard-icon userdashboard-close_icon' onClick={OpenSideFilterbar}>X</span>
      </div> */}

        <div className='sidebar-title'>
            {/* <div className='sidebar-brand'>
                <BsPersonCircle  className='userdashboard-icon_header'/> Student Name
            </div> */}
            <span onClick={OpenSideFilterbar}>X</span>
        </div>

      <Grid item xs sm md>
        {/* Course Pricing Filter */}
        <FormControl sx={{ m: 3 }} className='course-left-bar-form-control1'>
          <FormLabel className="course-left-box1-header">Course Pricing</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={(event) => handlePricingChange(event, 0)} />}
              label="Free Course"
            />
            <FormControlLabel
              control={<Checkbox onChange={(event) => handlePricingChange(event, 1)} />}
              label="Paid Course"
            />
            <FormControlLabel
              control={<Checkbox onChange={(event) => handlePricingChange(event, 2)} />}
              label="Scholarship"
            />
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs sm md>
        {/* Course Category Filter */}
        <FormControl sx={{ m: 3 }} className='course-left-bar-form-control2'>
          <FormLabel className="course-left-box1-header">Course Category</FormLabel>
          <FormGroup className='course-left-bar-formgroup'>
            {categories.length > 0 ? (
              categories.map((category) => (
                <FormControlLabel
                  key={category.id}
                  control={
                    <Checkbox
                      onChange={(event) => handleCategoryChange(event, category.id)}
                      checked={selectedCategories.includes(category.id)}
                    />
                  }
                  label={category.name}
                />
              ))
            ) : (
              <p>Loading categories...</p>
            )}
          </FormGroup>
        </FormControl>
      </Grid>
    </aside>
  );
};

export default CourseLeftBar;
