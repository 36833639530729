import axios from 'axios'
import { Button, Card } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import { CardText, CardTitle } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const ExamList = () => {
    const [allExams, setAllExams] = useState([])
    useEffect(() => {
        const response = axios.get(`http://127.0.0.1:8000/api/exams/`)
            .then((data) => {

                console.log("respo", data?.data)
                setAllExams(data?.data)
            })
            .catch((error) => {
                console.log("fetching error", error)
            })

    }, [])

    return (
        <div>
            <h2>
                All exams
            </h2>
            <div className=''>
                {
                    allExams?.map((exam) => (
                        <div>
                            <Card>
                                <CardTitle>

                                    {exam?.title}
                                </CardTitle>
                                <CardText>
                                    {exam?.description}
                                </CardText>
                                <span>
                                    Time:   {exam?.duration_minutes}
                                </span>
                                <Link to={`../exam/${exam?.id}`}>
                                
                                <Button>
                                    Start Exam
                                </Button>
                                </Link>
                            </Card>

                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ExamList