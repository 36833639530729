import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import AuthContext from "../../context/AuthContext";
import "./Exams.css"; // Include your CSS file here
import { fetchExamData } from "../../services/examService";

const Exams = ({exams}) => {
  const [examsData, setExamsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { authTokens } = useContext(AuthContext);
  const token = authTokens?.access;

  useEffect(() => {
    const getExams = async () => {
      try {
        setLoading(true);
        const data = await fetchExamData(token); // Call the service function
        setExamsData(data);
      } catch (error) {
        console.error("Failed to load exam data:", error.message);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      getExams();
    }
  }, [token]);

  const renderChart = (exam) => {
    const chartData = {
      labels: ["Total Score", "Passing Score"],
      datasets: [
        {
          label: "Score Comparison",
          data: [exam.total_score || 0, exam.exam.pass_score],
          backgroundColor: ["#4CAF50", "#F87171"],
        },
      ],
    };

    const chartOptions = {
      plugins: {
        legend: { display: false },
      },
      scales: {
        y: { beginAtZero: true, ticks: { stepSize: 1 } },
      },
    };

    return <Bar data={chartData} options={chartOptions} />;
  };

  if (loading) {
    return (
      exams &&  <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }

  if (!examsData || examsData.length === 0) {
    return (
      exams&&   <div className="no-data">No exam results available at the moment.</div>
    );
  }

  return (
    exams && <div className="exams-container">
      <h1 className="page-title">Exam Results</h1>
      <div className="exam-grid">
        {examsData.map((examData) => (
          <motion.div
            key={examData.id}
            className="exam-card"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <div className="card-header">
              <h2>{examData.exam.title}</h2>
              <p>{examData.exam.description}</p>
              <p>Duration: {examData.exam.duration_minutes} minutes</p>
            </div>
            <div className="card-body">
              <p>
                <strong>Start Time:</strong>{" "}
                {examData.start_time
                  ? new Date(examData.start_time).toLocaleString()
                  : "N/A"}
              </p>
              <p>
                <strong>End Time:</strong>{" "}
                {examData.end_time
                  ? new Date(examData.end_time).toLocaleString()
                  : "N/A"}
              </p>
              <p>
                <strong>Total Score:</strong>{" "}
                {examData.total_score !== null
                  ? examData.total_score
                  : "Pending"}
              </p>
              <p>
                <strong>Pass Score:</strong> {examData.exam.pass_score}
              </p>
              <p
                className={`status ${
                  examData.total_score === null
                    ? "pending"
                    : examData.is_passed
                    ? "passed"
                    : "failed"
                }`}
              >
                {examData.total_score === null
                  ? "Exam Pending"
                  : examData.is_passed
                  ? "Passed 🎉"
                  : "Failed ❌"}
              </p>
              {examData.total_score !== null && (
                <div className="chart-container">{renderChart(examData)}</div>
              )}
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Exams;
