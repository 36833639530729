import { Box, Card, Grid, Typography } from '@mui/material'
import React, {useState,useEffect} from 'react'
import '../CSS/coursedetails.css'
import Navbar from '../../components/Navbar'
import { useTranslation } from 'react-i18next';
import CourseMiddleComponent from './CourseMiddleComponent';
import '../CSS/course-middle-component.css'
import Footer from '../../components/MyFooter';
import CourseAccordion from './CourseAccordion';
import CourseDetailsFaq from './CourseDetailsFaq';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import config from '../../config';
import ExamPage from './ExamPage';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

const CourseDetails = () => {
    const { id } = useParams();
    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(false);
    const [error, setError] = useState(null);

    const { t } = useTranslation();
    const [showCourseDetails, setShowCourseDetails] = useState(true);
    const [showSyllabus, setShowSyllabus] = useState(false);
    const [showAssignment, setShowAssignment] = useState(false);
    const [showExam, setShowExam] = useState(false);

    const CourseDetails = () =>{
        setShowCourseDetails(!showCourseDetails);
        setShowSyllabus(false);
        setShowAssignment(false);
        setShowExam(false);
    }
    const Syllabus = () =>{
        setShowSyllabus(!showSyllabus);
        setShowAssignment(false);
        setShowExam(false);
        setShowCourseDetails(false);
    }
    const Assignment = () =>{
        setShowAssignment(!showAssignment);
        setShowExam(false);
        setShowCourseDetails(false);
        setShowSyllabus(false);
    }
    const Exam = () =>{
        setShowExam(!showExam);
        setShowCourseDetails(false);
        setShowSyllabus(false);
        setShowAssignment(false);
    }
    useEffect(() => {
      const fetchCourses = async ()=>{
        try {
            const response = await axios.get(`${config.apiUrl}course/${id}`)
            setCourse(response.data)
        } catch (error) {
            setError(error.message)
        }
        finally{
            setLoading(false)
        }
      }
      fetchCourses();
    
    }, [id])
    if (loading) return <p className="text-center text-lg">Loading...</p>;
    if (error) return <p className="text-center text-lg text-red-500">Error: {error}</p>;
  
    if (!course) return <p className="text-center text-lg">Course not found</p>;
    
  return (
    <div>
        <Navbar/>
        <div className="course-container centered">
        {/* <firstRowElement1 className="first-row-element-1 text-xl centered">
            {t("Courses")}
        </firstRowElement1>
        <input 
        className="first-row-element-2 text-xl text-centered"
        placeholder={t("Search")}
        />
        <firstRowElement3 className="first-row-element-3 text-xl centered">
            {t("Subscription")}
        </firstRowElement3>
        
        <firstRowElement4 className="first-row-element-4 text-xl centered">
            {t("Join Free Class")}
        </firstRowElement4> */}
        </div>
        <div className='course-middle-text'>
        {course.courseTitle}
        </div>
            
        

        <CourseMiddleComponent course={course}/>

        {/* <Container fluid>
        <Row align="center" justify="center" style={{ height: '300px' }}> 
            <Col xs={1.5} xl md lg  debug style={{ backgroundColor: '#FB6A77', color:'white', fontWeight:'bold'}}>
            Course Details
            </Col>
            <Col xs={1.5} debug style={{ backgroundColor: '#C1D5F6', color:'black', fontWeight:'bold'}}>
            Syllabus
            </Col>
            <Col xs={1.5} debug style={{ backgroundColor: '#ECE1E1', color:'black', fontWeight:'bold'}}>
            Project/Assignment
            </Col>
            <Col xs={1.5} debug style={{ backgroundColor: '#C1D5F6', color:'black', fontWeight:'bold'}}>
            Exam
            </Col>
        </Row>
        </Container> */}

        <div className="course-details-container course-centered" >
            <div className= {showCourseDetails ? "second-row-element-1-selected text-xl course-centered":"second-row-element-1 text-xl course-centered"} onClick={CourseDetails}>
                {t("Course Details")}
            </div>
            <div 
            className={showSyllabus ? "second-row-element-2-selected text-xl course-centered" : "second-row-element-2 text-xl course-centered"}
            onClick={Syllabus}
            >
                {t("Syllabus")}

            </div>
            
            <div className= {showAssignment? "second-row-element-3-selected text-xl course-centered" : "second-row-element-3 text-xl course-centered"} onClick={Assignment}>
                {t("Project/Assignment")}
            </div>
            
            <div
             className={showExam? "second-row-element-4-selected text-xl course-centered" : "second-row-element-4 text-xl course-centered"} onClick={Exam}>
                {t("Exam")}
            </div>
        </div>
        {
            showCourseDetails && <CourseDetailsFaq courseId={course?.courseId}/>
        }
        
        {
            showSyllabus &&
            <div className='course-syllabus-part'>
                Syllabus

            {/* <Card className='course-details-box2' elevation={3}>
                <Box className='course-details-box2-1'>
                    <li>HSC Examinee</li>
                    <li>HSC Examinee</li>
                    
                </Box>
                <Box className='course-details-box2-1'>
                    <li>HSC Examinee</li>
                    <li>HSC Examinee</li>
                </Box>
            </Card> */}
                <section className="course-details-box2">
                    <ul className="syllabus-list-container">
                    <ul className="syllabus-list">
                        <li>Module 1: React Basics</li>
                        <li>Module 2: State Management</li>
                        <li>Module 3: React Router</li>
                        <li>Module 4: Project Development</li>
                    </ul>
                    <ul className="syllabus-list">
                        <li>Module 5: React Basics</li>
                        <li>Module 6: State Management</li>
                        <li>Module 7: React Router</li>
                        <li>Module 8: Project Development</li>
                    </ul>
                    <ul className="syllabus-list">
                        <li>Module 9: React Basics</li>
                        <li>Module 10: State Management</li>
                        <li>Module 11: React Router</li>
                        <li>Module 12: Project Development</li>
                    </ul>
                    </ul>
                </section>
            </div>
        }

        {
            showAssignment &&
            <div className='course-syllabus-part'>
                Assignment

            <Card className='course-details-box2' elevation={3}>
                <Box className='course-details-box2-1'>
                    <li>HSC Examinee</li>
                    <li>HSC Examinee</li>
                    
                </Box>
                <Box className='course-details-box2-1'>
                    <li>HSC Examinee</li>
                    <li>HSC Examinee</li>
                </Box>
            </Card>
            </div>
        }

        {
            showExam &&
            <div className='course-syllabus-part'>
            {/* <Card className='course-details-box2' elevation={3}>
                <Box className='course-details-box2-1'>
                    <li>HSC Examinee</li>
                    <li>HSC Examinee</li>
                    
                </Box>
                <Box className='course-details-box2-1'>
                    <li>HSC Examinee</li>
                    <li>HSC Examinee</li>
                </Box>
            </Card> */}
            <ExamPage exams={course?.exams}/>
            </div>
        }
        

        {/* <div className='course-details-text-1'>
            What will you learn from this course?
        </div>

        <Card className='course-details-box1' elevation={3}>
            <Box className='course-details-box1-1'>
                <li>HSC Physics</li>
                <li>HSC Physics</li>
                <li>HSC Physics</li>
                <li>HSC Physics</li>
            </Box>
            <Box className='course-details-box1-1'>
                <li>HSC Physics</li>
                <li>HSC Physics</li>
                <li>HSC Physics</li>
                <li>HSC Physics</li>
            </Box>
        </Card>

        <div className='course-details-text-2'>
            For whom is this course?
        </div>

        <Card className='course-details-box2' elevation={3}>
            <Box className='course-details-box2-1'>
                <li>HSC Examinee</li>
                <li>HSC Examinee</li>
                
            </Box>
            <Box className='course-details-box2-1'>
                <li>HSC Examinee</li>
                <li>HSC Examinee</li>
            </Box>
        </Card>

        <div className='course-details-text-3'>
            Why do you do this course?
        </div>

        <Card className='course-details-box2' elevation={3}>
            <Box className='course-details-box2-1'>
                <li>HSC Examinee</li>
                <li>HSC Examinee</li>
                
            </Box>
            <Box className='course-details-box2-1'>
                <li>HSC Examinee</li>
                <li>HSC Examinee</li>
            </Box>
        </Card> */}

        <div className='course-details-text-3'>
            Course Curriculum
        </div>

        <CourseAccordion className="course-details-accordion1"  course={course}/>

        

        {/* <Box className='course-details-box-1'>

        </Box> */}
        {/* <Grid container my={4}>
            <Grid item>
                Course Details
            </Grid>
            <Grid item>
                Syllabus
            </Grid>
            <Grid item>
                Project/Assignment
            </Grid>
            <Grid item>
                Exam
            </Grid>
        </Grid> */}
        <Footer/>
    </div>
  )
}

export default CourseDetails