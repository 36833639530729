import React from 'react';
import './exampage.css';
import { Button, Card } from '@mui/material';
import { Link } from 'react-router-dom';

const ExamPage = ({ exams }) => {
  return (
    <div className="exam-page">
      {/* Page Header */}
      <div className="exam-header">
        <h1>Available Exams</h1>
        <p>Select an exam to start</p>
      </div>

      {/* Exam List */}
      <div className="exam-list">
        {exams?.map((exam) => (
          <Card className="exam-card" key={exam?.id}>
            <div className="exam-card-content">
              <h2 className="exam-title">{exam?.title}</h2>
              <p className="exam-description">{exam?.description}</p>
              <div className="exam-info">
                <span>Time: {exam?.duration_minutes} mins</span>
              </div>
            </div>
            <Link to={`../exam/${exam?.id}`} className="exam-link">
              <Button variant="contained" className="start-exam-button">
                Start Exam
              </Button>
            </Link>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default ExamPage;
