import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './settings.css'
import config from '../../config'
import Swal from 'sweetalert2';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Button } from '@mui/base';

const Settings = ({ settings }) => {
  const AUTH_URL = config.authUrl

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const validatePassword = (password) => {
    if (password.length < 6) {
      return 'Password must be at least 6 characters long.';
    }
    return '';
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setPasswordError(validatePassword(e.target.value));
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError(e.target.value !== newPassword ? 'Passwords do not match.' : '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    setError('');

    // Check if new password and confirm password match
    if (newPassword !== confirmPassword) {
      setError('New password and confirm password do not match.');
      setLoading(false);
      return;
    }

    // Check for password length validation
    const passwordError = validatePassword(newPassword);
    if (passwordError) {
      setError(passwordError);
      setLoading(false);
      return;
    }

    // Retrieve the token from local storage
    const authToken = JSON.parse(localStorage.getItem('authTokens'));
    const accessToken = authToken?.access;
    console.log("the access token ", accessToken);
    try {

      const response = await fetch(
        `${AUTH_URL}changepass/`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,  // Include JWT token here
          },
          body: JSON.stringify({
            old_password: oldPassword,
            new_password: newPassword,
          }),
        }
      );
      console.log("the response", response)
      if (response.ok) {
        setMessage('Password changed successfully.');
        Swal.fire({
          title: "Alhamdulillah",
          text: "Your password has been Updated",
          icon: "success"
        });
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        const data = await response.json();
        if (data.old_password) {
          // Display an error message for incorrect old password
          Swal.fire({
            title: "Wrong Password",
            text: "Your password is Wrong",
            icon: "warning"
          });
          console.log("Error: Incorrect old password");
        } else {
          Swal.fire({
            title: "Error occurred",
            text: "Error changing password. Please check your input.",
            icon: "warning"
          });
        }
      }

    } catch (err) {
      setError(err.response?.data?.old_password || 'Failed to change password.');
      console.log("Error", err);
    } finally {
      setLoading(false);
    }
  };
  return (
    settings &&
    <div className='user-profile-settings-holder'>
      <motion.form
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          transition={{ type: 'spring', stiffness: 50 }}
          className="change-password-form"
        >
      <div className="input-container">
            <input
              type={showOldPassword ? 'text' : 'password'}
              placeholder="Enter old password"
              value={oldPassword}
              name="old_password"
              onChange={(e) => setOldPassword(e.target.value)}
              className="change-password-input"
              required
            />
            <button type="button" onClick={() => setShowOldPassword(!showOldPassword)} className="show-password-button">
              {showOldPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>

        <div className="input-container">
            <input
              type={showNewPassword ? 'text' : 'password'}
              placeholder="Enter new password"
              value={newPassword}
              name="new_password"
              onChange={handleNewPasswordChange}
              className="change-password-input"
              required
            />
            <button type="button" onClick={() => setShowNewPassword(!showNewPassword)} className="show-password-button">
              {showNewPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>

          {passwordError && <p className="validation-error">{passwordError}</p>}
          <div className="input-container">
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder="Confirm new password"
              value={confirmPassword}
              name="confirm_password"
              onChange={handleConfirmPasswordChange}
              className="change-password-input"
              required
            />
            <button type="button" onClick={() => setShowConfirmPassword(!showConfirmPassword)} className="show-password-button">
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {confirmPasswordError && <p className="validation-error">{confirmPasswordError}</p>}
          {loading ? (
            <div className="loading">Updating...</div>
          ) : (
           
            // <Button
            //   type="submit"
            //   className="change-password-button"
            // >
            //   Change Password
            // </Button>
            <div
              className="custom-button"
              onClick={handleSubmit} // Your function to handle click
              style={{
                backgroundColor: "#FBB03B",
                color: "#fff",
                padding: "10px 20px",
                textAlign: "center",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Change Password
            </div>
          )}
      </motion.form>
    </div>
  )
}

export default Settings