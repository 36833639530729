import React, { useState, useRef, useEffect } from 'react';
import Profile1 from '../../assets/profile1.webp';
import Photo_Upload_Image from '../../assets/photo_upload_image.jpg';
import { fetchUserData, updateUserData } from '../../services/userService';
import './profile.css';
import Swal from 'sweetalert2';

const Profile = ({ profile, user, authTokens }) => {
  const [image, setImage] = useState(null); // Holds selected image file
  const [imagePreview, setImagePreview] = useState(""); // Preview URL for selected image
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = authTokens?.access;
  const [updating, setUpdating] = useState(false); // Loading state for updating profile
  const inputRef = useRef(null);

  useEffect(() => {
    const getUserData = async () => {
      try {
        setLoading(true);
        const data = await fetchUserData(user?.user_id, token);
        setUserData(data);
        setError(null);
      } catch (err) {
        setError('Failed to load user data');
      } finally {
        setLoading(false);
      }
    };

    getUserData();
  }, [user?.user_id, token]);
// console.log("user data",userData)
  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file); // Set image file for upload
      setImagePreview(URL.createObjectURL(file)); // Set image preview URL
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      setUpdating(true);

      // Create form data and append each field and image
      const formData = new FormData();
      formData.append("user", user.user_id);
      formData.append("firstName", userData?.firstName || "");
      formData.append("lastName", userData?.lastName || "");
      formData.append("institute", userData?.institute || "");
      formData.append("userClass", userData?.userClass || "");
      formData.append("guardiansNumber", userData?.guardiansNumber || "");
      formData.append("address", userData?.address || "");
      if (image) formData.append("userImage", image); // Append image if selected

      // Log FormData to confirm content
      formData.forEach((value, key) => {
        console.log(`${key}: ${value}`);
      });

      await updateUserData(user?.user_id, formData, token);
    //   alert("Profile updated successfully");
      Swal.fire({
        title: "Al'Hamdulillah!",
        text: "Your Profile is successfully updated!",
        icon: "success"
      });
    } catch (err) {
      alert("Failed to update profile");
      console.log("error",err)
    } finally {
      setUpdating(false);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p style={{ color: 'red' }}>{error}</p>;

  return (
    profile &&
    <div className='profile-holder'>
      <div className='profile-user-photo'>
        <img
          src={imagePreview || userData?.userImage || Photo_Upload_Image}
          alt=""
          className={imagePreview ? 'profile-image-display-after' : 'profile-image-display-after'}
          onClick={handleImageClick}
          title="Upload Profile Photo"
        />
        <input
          type='file'
          ref={inputRef}
          onChange={handleImageChange}
          style={{ display: 'none' }}
        />
        <div className='profile-name'>{userData?.firstName} {userData?.lastName}</div>
      </div>
        <form className='profile-info' onSubmit={handleUpdate}>
          <div className='profile-info-1'>
            <label>First Name
              <input
                name="firstName"
                placeholder='First Name'
                value={userData?.firstName || ""}
                onChange={handleInputChange}
              />
            </label>
            <label>Last Name
              <input
                name="lastName"
                placeholder='Last Name'
                value={userData?.lastName || ""}
                onChange={handleInputChange}
              />
            </label>
          </div>

          <div className='profile-info-2'>
            <label>Institute
              <input
                name="institute"
                placeholder='Institute Name'
                value={userData?.institute || ""}
                onChange={handleInputChange}
              />
            </label>
          </div>

          <div className='profile-info-2'>
            <label>Class
              <input
                name="userClass"
                placeholder='Batch'
                value={userData?.userClass || ""}
                onChange={handleInputChange}
              />
            </label>
          </div>

          <div className='profile-info-2'>
            <label>Guardian's Number
              <input
                name="guardiansNumber"
                placeholder="Guardian's Number"
                value={userData?.guardiansNumber || ""}
                onChange={handleInputChange}
              />
            </label>
          </div>

          <div className='profile-info-2'>
            <label>Address
              <input
                name="address"
                placeholder="Address"
                value={userData?.address || ""}
                onChange={handleInputChange}
              />
            </label>
          </div>

          <div className='profile-info-2'>
            <label>Phone
              <input
                name="phone"
                placeholder="Phone"
                value={userData?.username}
                onChange={handleInputChange}
                disabled
              />
            </label>
          </div>

          <div
            className="custom-button"
            onClick={handleUpdate} // Your function to handle click
            style={{
              backgroundColor: "#FBB03B",
              color: "#fff",
              padding: "10px 20px",
              textAlign: "center",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            {updating ? "Updating..." : "Update"}
          </div>
        </form>
    </div>
  );
};

export default Profile;
