import config from "../config";
import axios from 'axios';

export const fetchCourse = async (id, token) => {
    // ...function logic
    // console.log("authTokens", token)
    const response = await fetch(`${config.apiUrl}course/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) throw new Error('Failed to fetch Course data');
    return response.json();
  };

  export const fetchCourseFaq= async (courseId) => {
    // ...function logic
    // console.log("authTokens", token)
    const response = await fetch(`${config.apiUrl}CourseFaq/by-course/${courseId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) throw new Error('Failed to fetch CourseFaq data');
    return response.json();
  };
  
  