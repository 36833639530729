import React, { useContext, useEffect, useState } from "react";
import "./cart-page.css";
import Navbar from "../../components/Navbar";
import Footer from "../../components/MyFooter";
import { Button } from "flowbite-react";
import { FaTrash } from "react-icons/fa";
import AuthContext from "../../context/AuthContext";
import config from "../../config";
import { Link } from "react-router-dom";

const CartPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { cart, removeFromCart, clearCart } = useContext(AuthContext);

  const [cartItems, setCartItems] = useState([]);
  const [courseFee, setCourseFee] = useState(0);
  const [courseDiscount, setCourseDiscount] = useState(0);

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await fetch(`${config.apiUrl}courseapi`);
        const allCourses = await response.json();

        const filteredCourses = allCourses.filter((course) =>
          cart.includes(course.courseId)
        );
        setCartItems(filteredCourses);

        // Calculate total fee and discount
        const totalFee = filteredCourses.reduce(
          (sum, course) => sum + parseFloat(course.courseFee),
          0
        );
        const totalDiscount = filteredCourses.reduce(
          (sum, course) => sum + parseFloat(course.courseDiscount),
          0
        );

        setCourseFee(totalFee);
        setCourseDiscount(totalDiscount);
      } catch (error) {
        console.error("Failed to fetch courses:", error);
      }
    };

    if (cart.length > 0) {
      fetchCartItems();
    }
  }, [cart]);

  const removeItem = (id) => {
    const updatedItems = cartItems.filter((item) => item.courseId !== id);
    setCartItems(updatedItems);
    removeFromCart(id);

    // Recalculate totals
    const updatedFee = updatedItems.reduce(
      (sum, course) => sum + parseFloat(course.courseFee),
      0
    );
    const updatedDiscount = updatedItems.reduce(
      (sum, course) => sum + parseFloat(course.courseDiscount),
      0
    );

    setCourseFee(updatedFee);
    setCourseDiscount(updatedDiscount);
  };


  const calculateTotal = () => courseFee - courseDiscount;

  return (
    <div>
      <Navbar />
      <div className="cart-page">
        <div className="cart-items">
          <h2>🛒 Your Cart</h2>
          {cartItems.length === 0 ? (
            <p>Your cart is empty.</p>
          ) : (
            cartItems.map((item) => (
              <div key={item.id} className="cart-item">
                <img
                  src={`${config.authUrl}${item.bannerImg}`}
                  alt={item.courseTitle}
                  className="item-image"
                />
                <div className="item-info">
                  <h3>{item.courseTitle}</h3>
                  <p>Fee: {item.courseFee} TK</p>
                  <p>Discount: {item.courseDiscount} TK</p>
                </div>
                <div className="cart-item-actions">
                  <FaTrash
                    title="Delete"
                    onClick={() => removeItem(item.courseId)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            ))
          )}
          <div>
            <Button
              onClick={() => {
                clearCart();
                setCartItems([]);
                setCourseFee(0);
                setCourseDiscount(0);
              }}
            >
              Remove All
            </Button>
          </div>
        </div>

        <div className="order-summary">
          <h2>💳 Order Summary</h2>
          <div className="summary-item">
            <span>Subtotal:</span>
            <span>{courseFee}</span>
          </div>
          <div className="summary-item">
            <span>Discount:</span>
            <span>{courseDiscount}</span>
          </div>
          <div className="summary-item total">
            <span>Total:</span>
            <span>{courseFee-courseDiscount}</span>
          </div>
          <Link to={"/coursecheckout"} state = {{course:{'courseFee':courseFee,'courseDiscount':courseDiscount}}}>
            <Button className="checkout-button">Proceed to Checkout</Button>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CartPage;
