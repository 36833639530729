import "./HeroStyle.css";
import React,{useState} from "react";
import Banner from "../assets/Banner.jpg"
import Tagline from "../assets/Group 43.png"
import HeroSubmitPopUpMessage from "./HeroSubmitPopUpMessage";
import config from "../config";
import Swal from "sweetalert2";

function Hero(){
    const [popUpMessage, setPopUpMessage] = useState(false);
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)


    const PopUpMessageWindow =()=>{ 
        setPopUpMessage(!popUpMessage);
    }
    const submitHandler = async () => {
        if (!name || !phone || !email) {
            // alert("All fields are required!");
            Swal.fire({
                title: "Failed to submit!",
                text: "All fields are required!",
                icon: "warning"
              });
            return;
        }
        setLoading(true);
        
        try {
            const response = await fetch(`${config.apiUrl}draftUser/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, phone, email }),
            });

            if (response.ok) {
                setName('');
                setPhone('');
                setEmail('');
                
                Swal.fire({
                        title: "Jajak'Allahu Khairan !",
                        text: "Thanks for submitting!",
                        icon: "success"
                      });
                      setLoading(false)
            } else {
                const errorData = await response.json();
                setLoading(false)
                
                alert(`Failed to submit: ${errorData.message || 'Something went wrong'}`);
                Swal.fire({
                    title: "Failed to submit!",
                    text: "Something went wrong!",
                    icon: "warning"
                  });
            }
        } catch (error) {
            console.error("Error submitting data:", error);
            setLoading(false)

            Swal.fire({
                title: "Failed to submit!",
                text: "Something went wrong!",
                icon: "warning"
              });
        }
    };
    return (
        
        <div className="hero">
            <img alt="HeroImg" src={Banner} className="hero-cover-image"/>
            
            <img alt="TagImg" src={Tagline} className="hero-tagline-image"/>
            
            {/* <form className="hero-login">
                <div className="hero-text">
                    For Details...
                </div>
                <div className="hero-phone">
                    <input placeholder="Phone" type="text" name="name" />
                </div>
                <div className="hero-email">
                    <input  placeholder="Email" type="text" name="name" />
                </div>
                <div className="hero-submit">
                    Submit
                </div>
            </form> */}

            <div className="hero-login">
                <div className='hero-header'>
                    <div className='hero-text'>For Details</div>
                    <div className='hero-underline'></div>
                </div>
                <div className='hero-inputs'>
                    <div className='hero-input'>
                        <input
                            type='text'
                            placeholder='Name'
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className='hero-input'>
                        <input
                            type='phone'
                            placeholder='Phone'
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                    <div className='hero-input'>
                        <input
                            type='email'
                            placeholder='Email'
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className='hero-submit-container' >
                        <div className='hero-submit' onClick={submitHandler}>Submit</div>
                    </div>
                </div>
            </div>
            <HeroSubmitPopUpMessage trigger={popUpMessage} setTrigger={setPopUpMessage} className='hero-pop-up-message'>
                <div className="pop-up-message">
                Thank you for contacting us. We will contact you soon.
                </div>
            </HeroSubmitPopUpMessage>
        </div>

        
        
    )
}

export default Hero;