import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBangladeshiTakaSign } from '@fortawesome/free-solid-svg-icons';
import CourseImage1 from '../../assets/course_image.png';
import config from '../../config';
import '../CSS/courseCard.css';
import AuthContext from '../../context/AuthContext';
import defaultImage from '../../assets/default.jpg';
const CourseCard = ({ course }) => {
    let navigate = useNavigate();
    const { userInfo, addToCart, cart } = useContext(AuthContext)
    const demofn = (id) => {
        console.log("att to cart clicked!!")
        addToCart(id);
    }

    return (
        <Box className='course-card-box'>
            <Card elevation={3}>
                <Link to={`../course/${course?.courseId}/`}>
                    <CardMedia
                        component='img'
                        height={140}
                        image={course?.bannerImg ? `${config.authUrl}${course.bannerImg}` : CourseImage1}
                        alt='Course'
                        className='course-card-image'
                    />
                    <CardContent className='course-card-content'>
                        <Typography gutterBottom variant="h5" component='div' color='#1556FC' className='course-card-content-title'>
                            {course?.courseTitle}
                        </Typography>
                        <Typography variant="body2" color='black' className='course-card-content-topiclist'>
                            {course?.topicList}
                        </Typography>
                    </CardContent>
                </Link>

                <CardActions className='course-course-card-content'>
                    <div className='course-card-review'>
                        <div1>
                            <div11>{course?.classNumber} class</div11>
                            <div12>|</div12>
                            <div13>{course?.courseDuration} Months</div13>
                        </div1>
                        <div2>
                            {course?.courseRating}
                            <div21>*</div21>
                            <div21>*</div21>
                            <div21>*</div21>
                            <div21>*</div21>
                            <div21>*</div21>
                            (245)
                        </div2>
                    </div>

                    <div className='course-course-card-footer-content'>
                        <div className='course-card-footer-content-title'>Course Fee</div>
                        <div className='course-card-footer-content-fee'>
                            <div className='course-card-footer-content-fee-actual'>
                                {course?.courseFee}
                                <FontAwesomeIcon icon={faBangladeshiTakaSign} />
                            </div>
                            <div className='course-card-footer-content-fee-discounted'>
                                {course?.courseFee - course.courseDiscount}
                                <FontAwesomeIcon icon={faBangladeshiTakaSign} />
                            </div>
                        </div>
                    </div>

                    {userInfo?.enrolledCourses?.includes(course?.courseId) ? (
                        <Link to={`../coursedetailsuser/${course?.courseId}/`} state={{ course: course }}>
                            <div className='play-button'>Play Course</div>
                        </Link>
                    ) : (
                        <>
                            {
                                cart?.includes(course?.courseId) ? <div className='enroll-button' onClick={() => navigate('../cartpage')}>Go to Cart
                                </div> : <div className='enroll-button' onClick={() => demofn(course?.courseId)}>Add to Cart
                                </div>
                            }

                        </>
                    )}
                </CardActions>
            </Card>
        </Box>
    );
};

export default CourseCard;
