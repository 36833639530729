import config from "../config";
import axios from 'axios';

export const createPayment = async (formData, token) => {
    // Create a new payment entry by sending the form data
    console.log("formData",formData)
    return axios.post(`${config.apiUrl}payment/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });
  };