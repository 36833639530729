import React, { useState } from 'react'
import "../CSS/course-middle-component.css"
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const CourseTabsInPlayed = () => {
    const { id } = useParams();
    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { t } = useTranslation();
    const [showCourseDetails, setShowCourseDetails] = useState(true);
    const [showSyllabus, setShowSyllabus] = useState(false);
    const [showAssignment, setShowAssignment] = useState(false);
    const [showExam, setShowExam] = useState(false);

    const CourseDetails = () => {
        setShowCourseDetails(!showCourseDetails);
        setShowSyllabus(false);
        setShowAssignment(false);
        setShowExam(false);
    }
    const Syllabus = () => {
        setShowSyllabus(!showSyllabus);
        setShowAssignment(false);
        setShowExam(false);
        setShowCourseDetails(false);
    }
    const Assignment = () => {
        setShowAssignment(!showAssignment);
        setShowExam(false);
        setShowCourseDetails(false);
        setShowSyllabus(false);
    }
    const Exam = () => {
        setShowExam(!showExam);
        setShowCourseDetails(false);
        setShowSyllabus(false);
        setShowAssignment(false);
    }
    return (
        <>

            <div className="course-details-container course-centered my-0" >
                <div className="second-row-element-1 text-xl course-centered" onClick={CourseDetails}>
                    {t("Course Details")}
                </div>
                <div
                    className="second-row-element-2 text-xl course-centered"
                    onClick={Syllabus}
                >
                    {t("Syllabus")}

                </div>

                <div className="second-row-element-3 text-xl course-centered" onClick={Assignment}>
                    {t("Project/Assignment")}
                </div>

                <div
                    className="second-row-element-4 text-xl course-centered" onClick={Exam}>
                    {t("Exam")}
                </div>
            </div>
        </>
    )
}

export default CourseTabsInPlayed