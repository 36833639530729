import React, { useState, useContext, useEffect } from 'react';
import { FaVideo, FaChevronDown, FaChevronRight, FaChevronLeft, FaChevronRight as FaArrowRight } from 'react-icons/fa';
import ReactPlayer from 'react-player';
import Navbar from '../../components/Navbar';
import { Document, Page, pdfjs } from 'react-pdf';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import QuizIcon from '@mui/icons-material/Quiz';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetchCourse } from '../../services/courseService';
import AuthContext from '../../context/AuthContext';
import Swal from 'sweetalert2'; // Import SweetAlert
import { Button } from '@mui/material';
import CourseTabsInPlayed from './CourseTabsInPlayed';

const CoursePlayed = () => {
    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
        'pdfjs-dist/build/pdf.worker.min.mjs',
        import.meta.url
    ).toString();
    

    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
}, [ ])

    const navigate = useNavigate();
    const { id } = useParams();
    const { authTokens, user,userInfo } = useContext(AuthContext);
    const token = authTokens?.access;
    const [modules, setModules] = useState([]);
    const [currentVideoIndex, setCurrentVideoIndex] = useState({ moduleIndex: 0, contentIndex: 0 });
    const [courseId, setCourseId] = useState(null);

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    useEffect(() => {
        const getCourseDetails = async () => { 
            try {
                const courseDetails = await fetchCourse(id, token);
                console.log("Course Details", courseDetails)
                setModules(courseDetails.modules.map(module => ({
                    ...module,
                    isOpen: false,
                    contents: module.contents.map(content => ({
                        ...content,
                        icon: content.contentType === 1 ? <FaVideo /> : <AutoStoriesIcon />,
                    })),
                    exams:courseDetails?.exams.filter(exam=>exam.courseId===courseId)
                })));
                setCourseId(courseDetails.courseId);
            } catch (error) {
                console.error('Error fetching course data:', error);
            }
        };

        getCourseDetails();
       
    }, [id,courseId ,token]);

    console.log("details of modules",modules)
    

    useEffect(() => {
        if (!user) {
            Swal.fire({
                title: 'Warning!',
                text: 'You are not Logged in',
                icon: 'warning',
                confirmButtonText: 'Ok',
            });
            navigate('/login');
        }
        if (courseId && !userInfo?.enrolledCourses?.includes(courseId)) {
            Swal.fire({
                title: 'Warning!',
                text: 'Please Enroll First',
                icon: 'warning',
                confirmButtonText: 'Ok',
            });
            navigate('../course');
        }
    }, [user,userInfo, navigate,courseId]);

    const toggleModule = (index) => {
        setModules(modules.map((mod, i) => (i === index ? { ...mod, isOpen: !mod.isOpen } : mod)));
    };

    const getCurrentContent = () => {
        const { moduleIndex, contentIndex } = currentVideoIndex;
        return modules[moduleIndex]?.contents[contentIndex] || {};
    };

    const handleNext = () => {
        const { moduleIndex, contentIndex } = currentVideoIndex;
        const currentModule = modules[moduleIndex];
        if (contentIndex < currentModule.contents.length - 1) {
            setCurrentVideoIndex({ moduleIndex, contentIndex: contentIndex + 1 });
        } else if (moduleIndex < modules.length - 1) {
            setCurrentVideoIndex({ moduleIndex: moduleIndex + 1, contentIndex: 0 });
        }
    };

    const handlePrevious = () => {
        const { moduleIndex, contentIndex } = currentVideoIndex;
        if (contentIndex > 0) {
            setCurrentVideoIndex({ moduleIndex, contentIndex: contentIndex - 1 });
        } else if (moduleIndex > 0) {
            const previousModule = modules[moduleIndex - 1];
            setCurrentVideoIndex({ moduleIndex: moduleIndex - 1, contentIndex: previousModule.contents.length - 1 });
        }
    };

    const currentContent = getCurrentContent();

    return (
        <>
            <Navbar />
            {user && (
                <div className="flex flex-col h-screen md:flex-row ">
                    {/* Sidebar */}
                    <div className="w-full md:w-72 bg-gray-200 p-4 overflow-y-auto md:mt-28 mt-16  pb-40">
                        <div className="bg-yellow-400 p-3 rounded-md text-center font-bold text-lg mb-4">
                            Course Content
                        </div>
                        {modules.map((module, index) => (
                            <div key={index}>
                                <div
                                    className="flex items-center cursor-pointer p-3 bg-gray-300 rounded-md mb-2 hover:bg-gray-400"
                                    onClick={() => toggleModule(index)}
                                >
                                    <AutoStoriesIcon className="course-details-accordion-icon" />
                                    <span className="ml-3 font-medium">{module.moduleTitle}</span>
                                    <span className="ml-auto">
                                        {module.isOpen ? <FaChevronDown /> : <FaChevronRight />}
                                    </span>
                                </div>
                                {module.isOpen && (
                                    <div className="ml-5">
                                        {module.contents.map((content, contentIndex) => (
                                            <div
                                                key={content.id}
                                                className={`flex items-center cursor-pointer p-2 hover:bg-gray-300 rounded-md ${
                                                    currentVideoIndex.moduleIndex === index &&
                                                    currentVideoIndex.contentIndex === contentIndex
                                                        ? 'text-blue-500 font-semibold'
                                                        : ''
                                                }`}
                                                onClick={() =>
                                                    setCurrentVideoIndex({ moduleIndex: index, contentIndex })
                                                }
                                            >
                                                <span className="flex-shrink-0">{content.icon}</span>
                                                <span className="ml-3">{content.title}</span>
                                            </div>
                                        ))}
                                        {
                                            module.exams.filter(exam=>exam.moduleId === module.moduleId).map((singleExam)=>(
                                               
                                                   <div
                                                key={singleExam?.id}
                                                className={`text-red-500 font-semibold flex items-center cursor-pointer p-2 hover:bg-gray-300 rounded-md `}
                                               
                                            >
                                                <Link to={`../exam/${singleExam.id}`}>
                                                <span className="flex-shrink-0"><QuizIcon/></span>
                                                <span className="ml-3">{singleExam.title}</span>
                                                </Link>
                                            </div>
                                                 
                                            ))
                                        }
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>

                    {/* Main Content */}
                    <div className="flex-1 flex flex-col items-center justify-center bg-gray-100 md:mt-28 md:mb-10 ">
                        {modules.length > 0 && (
                            <div className="flex justify-between items-center w-11/12 md:w-3/4 mb-4">
                                <button
                                    className="flex items-center p-2 bg-blue-500 text-white rounded-md disabled:bg-gray-400"
                                    onClick={handlePrevious}
                                    disabled={
                                        currentVideoIndex.moduleIndex === 0 &&
                                        currentVideoIndex.contentIndex === 0
                                    }
                                >
                                    <FaChevronLeft className="mr-2" />
                                    Previous
                                </button>
                                <h2 className="text-lg font-semibold text-center mx-4">
                                    {currentContent.title}
                                </h2>
                                <button
                                    className="flex items-center p-2 bg-blue-500 text-white rounded-md disabled:bg-gray-400"
                                    onClick={handleNext}
                                    disabled={
                                        currentVideoIndex.moduleIndex === modules.length - 1 &&
                                        currentVideoIndex.contentIndex ===
                                            modules[modules.length - 1].contents.length - 1
                                    }
                                >
                                    Next
                                    <FaArrowRight className="ml-2" />
                                </button>
                            </div>
                        )}

                        {currentContent.file ||currentContent.videoLink? (
                            currentContent.contentType != 3 ? (
                             
                                <ReactPlayer
                                    url={currentContent?.file?.length>0?currentContent.file:currentContent.videoLink}
                                    controls
                                    width="90%"
                                    className="max-w-full"
                                    height='400px'
                                    playing
                                    onContextMenu={(e) => e.preventDefault()}
                                    />
                                  
                            ) : (
                                <div className="flex flex-col items-center w-full h-full px-6">
                                    <div
                                        className="w-full overflow-y-auto bg-gray-100 p-4 rounded-md shadow-md"
                                        style={{ maxHeight: '80vh' }}
                                    >
                                        <Document
                                            file={currentContent.file}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                            className="w-full justify-center flex"
                                        >
                                            <Page
                                                pageNumber={pageNumber}
                                                scale={0.7}
                                                renderTextLayer={false}
                                                renderAnnotationLayer={true}
                                                className="border w-auto"
                                                // width={'400'}
                                                // height={200}
                                            />
                                        </Document>
                                    </div>
                                    <div className="flex justify-center items-center mt-4 space-x-4">
                                        <Button
                                        variant='contained'
                                            className="p-2 bg-blue-500 text-white rounded-md disabled:bg-gray-400"
                                            onClick={() => setPageNumber((prev) => Math.max(prev - 1, 1))}
                                            disabled={pageNumber <= 1}
                                        >
                                            Previous Page
                                        </Button>
                                        <p>
                                            Page {pageNumber} of {numPages}
                                        </p>
                                        <Button
                                        variant='contained'
                                            className="p-2 bg-blue-500 text-white rounded-md disabled:bg-gray-400"
                                            onClick={() => setPageNumber((prev) => Math.min(prev + 1, numPages))}
                                            disabled={pageNumber >= numPages}
                                        >
                                            Next Page
                                        </Button>
                                    </div>
                                </div>
                            )
                        ) : (
                            <p className="text-gray-500">Select a content item to view</p>
                        )}
                        {/* <CourseTabsInPlayed /> */}
                       
                    </div>
                </div>
            )}
        </>
    );
};

export default CoursePlayed;
