import React, { useContext, useEffect, useRef, useState } from 'react'
import 
 {BsFillBellFill, BsFillEnvelopeFill, BsPersonCircle, BsSearch, BsJustify}
 from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import Logo_icon from '../../assets/logo Icon.png';
import AuthContext from '../../context/AuthContext';
import { fetchUserData } from '../../services/userService';
import Photo_Upload_Image from '../../assets/photo_upload_image.jpg';

function Header({OpenSidebar}) {
  let navigate = useNavigate(); 
    const routeChange = () =>{ 
        let path = `../`; 
        navigate(path);
    }
    const profileMenuRef = useRef(null);
    const profileIconRef = useRef(null);
    const { user, logoutUser,authTokens } = useContext(AuthContext);
    const [userData, setUserData] = useState(null);
    const token = authTokens?.access;

    //---------------------For Profile Menu------------------
    const [openMenu, setOpenMenu] = useState(false);
    const handleClickMenu = () => {
        setOpenMenu((prev) => !prev);
    };
    const handleClose = () => {
        setOpenMenu(false);
    };

    const handleClickOutside = (event) => {
        if (profileMenuRef.current && !profileMenuRef.current.contains(event.target) &&
            profileIconRef.current && !profileIconRef.current.contains(event.target)) {
            setOpenMenu(false);
        }
    };

    useEffect(() => {
        if (openMenu) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [openMenu]);

    

    useEffect(() => {
        const getUserData = async () => {
          try {
            const data = await fetchUserData(user?.user_id, token);
            setUserData(data);
          } catch (err) {
            console.log("error to fetchUserData",err)
          } 
        };
    
        getUserData();
      }, [user?.user_id, token]);

  return (
    <div className='userdashboard-header'>
        <div className='userdashboard-menu-icon'>
            <BsJustify className='userdashboard-icon' onClick={OpenSidebar}/>
        </div>
        <div className='userdashboard-home-icon' onClick={routeChange}>
          <img src={Logo_icon} alt="Logo_icon" />         
        </div>
        <div className='userdashboard-header-left'>
            <BsSearch className='userdashboard-icon'/>
            <input
            placeholder='Search'
            className='header-search'
            />
            
        </div>
        <div className='userdashboard-header-right'>
            <BsFillBellFill className='userdashboard-icon'/>
            <BsFillEnvelopeFill className='userdashboard-icon'/>
            <div 
                
                onClick={handleClickMenu}
                ref={profileIconRef}
                >
                {/* <i className="fas fa-bars"/> */}
                {/* <BsPersonCircle/> */}
                <img
                src={ userData?.userImage || Photo_Upload_Image}
                alt=""
                className="userdashboard-profile-icons"
                
                />

            </div>

            
            {openMenu &&
                
                <ul
                onClose={handleClose}
                className="userdashboard-profile-menu-list"
                ref={profileMenuRef}
                >
                    <li onClick={handleClose}>
                        My Account
                    </li>
                    <li onClick={handleClose}>
                        My Courses
                    </li>
                    <li onClick={handleClose}>
                        Settings
                    </li>
                    <li onClick={handleClose}>
                        Exam
                    </li>
                    <li onClick={handleClose}>
                        Language
                    </li>
                    <li onClick={handleClose}>
                        Notes
                    </li>
                    <li onClick={handleClose}>
                        Help
                    </li>
                    <li onClick={() => 
                      { handleClose(); 
                        logoutUser(); 
                        navigate('/')

                      }} className="userdashboard-profile-menu-logout">
                        Logout
                    </li>
                </ul>
                }
        </div>
    </div>
  )
}

export default Header