import React, {useEffect, useRef, useState} from "react";
import i18n from '../i18n';
import "./languageselector.css"
import PublicIcon from '@mui/icons-material/Public';
import { useTranslation } from "react-i18next";
import { BsGlobe } from 'react-icons/bs';

const LanguageSelector = () => {
    
    // const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.
    const { i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    // const chooseLanguage = (e) => {
    //     e.preventDefault();
    //     i18n.changeLanguage(e.target.value);   // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
    //     setSelectedLanguage(e.target.value);
    //     localStorage.setItem("lang", e.target.value);
    // }

    const handleToggle = () => {
        setIsOpen((prev) => !prev);
      };
    
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
      }, []);
    
      const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setIsOpen(false); // Close the dropdown after selection
      };

    return (
        <div className="language-switcher" ref={dropdownRef}>
        <BsGlobe className="language-switcher-button" onClick={handleToggle} />
        <div className={`language-dropdown ${isOpen ? 'show' : ''}`}>
            <div className="language-dropdown-item" onClick={() => changeLanguage('en')}>English</div>
            <div className="language-dropdown-item" onClick={() => changeLanguage('bn')}>বাংলা</div>
        </div>
        </div>
    );
};

export default LanguageSelector;