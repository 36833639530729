import React, { useState, useEffect, useRef } from 'react';
import {
  BsFillGrid3X3GapFill, BsPeopleFill,
  BsListCheck, BsMenuButtonWideFill, BsFillGearFill, BsPersonCircle, BsSpeedometer2
} from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import '../CSS/UserDashboard.css';
import Photo_Upload_Image from '../../assets/photo_upload_image.jpg';
import { fetchUserData } from '../../services/userService';

function Sidebar({
  openSidebarToggle, OpenSidebar, gotoEnrolledCoursesPage, gotoDashboardPage,
  gotoResultPage, gotoProfilePage, gotoExamPage, gotoReportPage, gotoSettingPage,
  user, authTokens
}) {
  const [userData, setUserData] = useState(null);
  const token = authTokens?.access;
  const sidebarRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const getUserData = async () => {
      try {
        const data = await fetchUserData(user?.user_id, token);
        setUserData(data);
      } catch (err) {
        console.log("Error fetching user data:", err);
      }
    };

    getUserData();
  }, [user?.user_id, token]);

  // Handle clicks outside the sidebar
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        if (openSidebarToggle) {
          OpenSidebar(); // Close the sidebar if open and clicked outside
        }
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [openSidebarToggle, OpenSidebar]);

  // Function to handle menu item clicks
  const handleMenuClick = (menuAction) => {
    menuAction();
    if (window.innerWidth < 768) {
      OpenSidebar(); // Close sidebar on small screens
    }
  };

  return (
    <aside
      id="userdashboard-sidebar"
      className={openSidebarToggle ? "sidebar-responsive" : ""}
      ref={sidebarRef}
    >
      <img
        src={userData?.userImage || Photo_Upload_Image}
        alt=""
        className="sidebar-profile-image-display-after"
      />
      <div className="sidebar-title">
        <div className="sidebar-brand">
          {userData?.firstName}
        </div>
        <span className="userdashboard-icon userdashboard-close_icon" onClick={OpenSidebar}>X</span>
      </div>

      <ul className="sidebar-list">
        <li className="sidebar-list-item" onClick={() => handleMenuClick(gotoProfilePage)}>
          <a href="">
            <BsPersonCircle className="userdashboard-icon" />
          </a>
          <text className="sidebar-list-item-title">{t("PROFILE")}</text>
        </li>
        <li className="sidebar-list-item" onClick={() => handleMenuClick(gotoDashboardPage)}>
          <a href="">
            <BsSpeedometer2 className="userdashboard-icon" />
          </a>
          <text className="sidebar-list-item-title">{t("DASHBOARD")}</text>
        </li>
        <li className="sidebar-list-item" onClick={() => handleMenuClick(gotoEnrolledCoursesPage)}>
          <a href="">
            <BsFillGrid3X3GapFill className="userdashboard-icon" />
          </a>
          <text className="sidebar-list-item-title">{t("ENROLLED COURSES")}</text>
        </li>
        <li className="sidebar-list-item" onClick={() => handleMenuClick(gotoExamPage)}>
          <a href="">
            <BsPeopleFill className="userdashboard-icon" />
          </a>
          <text className="sidebar-list-item-title">{t("EXAMS")}</text>
        </li>
        <li className="sidebar-list-item" onClick={() => handleMenuClick(gotoResultPage)}>
          <a href="">
            <BsListCheck className="userdashboard-icon" />
          </a>
          <text className="sidebar-list-item-title">{t("RESULTS")}</text>
        </li>
        <li className="sidebar-list-item" onClick={() => handleMenuClick(gotoReportPage)}>
          <a href="">
            <BsMenuButtonWideFill className="userdashboard-icon" />
          </a>
          <text className="sidebar-list-item-title">{t("REPORTS")}</text>
        </li>
        <li className="sidebar-list-item" onClick={() => handleMenuClick(gotoSettingPage)}>
          <a href="">
            <BsFillGearFill className="userdashboard-icon" />
          </a>
          <text className="sidebar-list-item-title">{t("SETTINGS")}</text>
        </li>
      </ul>
    </aside>
  );
}

export default Sidebar;
