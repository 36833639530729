import React from 'react';
import { Link } from 'react-router-dom';
import './saved-for-later-page.css';
import Navbar from '../../components/Navbar';
import Footer from '../../components/MyFooter';

const SavedForLaterPage = () => {
  const savedItems = [
    {
      id: 1,
      title: 'Course 1',
      description: 'This is a description of the first course.',
      image: 'https://via.placeholder.com/300',
      price: '$99.99'
    },
    {
      id: 2,
      title: 'Course 2',
      description: 'This is a description of the second course.',
      image: 'https://via.placeholder.com/300',
      price: '$79.99'
    }
  ];

  const handleMoveToCart = (id) => {
    console.log(`Item with id: ${id} moved to cart`);
  };

  return (
    <div>
      <Navbar/>
    <div className="saved-for-later-page">
      <h2>Saved for Later</h2>

      <div className="saved-for-later-items">
        {savedItems.map(item => (
          <div className="saved-for-later-item" key={item.id}>
            <img className="saved-for-later-item-image" src={item.image} alt={item.title} />
            <div className="saved-for-later-item-info">
              <h3>{item.title}</h3>
              <p>{item.description}</p>
              <p className="price">{item.price}</p>
            </div>

            <div className="saved-for-later-item-actions">
              <button onClick={() => handleMoveToCart(item.id)} className="move-to-cart-button">
                Move to Cart
              </button>
              <svg className="saved-for-later-heart-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" fill="#ff8c00" />
              </svg>
            </div>
          </div>
        ))}
      </div>

      <div className="saved-for-later-order-summary">
        <div className="saved-for-later-summary-item">
          <span>Total items</span>
          <span>2</span>
        </div>
        <div className="saved-for-later-summary-item">
          <span>Total Price</span>
          <span>$179.98</span>
        </div>
        <Link to="/checkout">
          <button className="saved-for-later-checkout-button">Proceed to Checkout</button>
        </Link>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default SavedForLaterPage;
