import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import React,{useState,useEffect, useContext} from 'react'
import CourseImage1 from '../../assets/course_image.png'
// import { blue } from '@mui/material/colors';
import { Link, useNavigate } from "react-router-dom";
import './enrolled-course-card.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBangladeshiTakaSign } from '@fortawesome/free-solid-svg-icons';
import { fetchCourse } from '../../services/courseService';
import config from '../../config';
import axios from 'axios';
import AuthContext from '../../context/AuthContext';

const EnrolledCourseCard = ({id,token}) => {
    const [course, setCourse] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const {userInfo} = useContext(AuthContext);
    useEffect(() => {
        const fetchCourses = async ()=>{
          try {
              const response = await axios.get(`${config.apiUrl}course/${id}`)
              setCourse(response.data)
          } catch (error) {
              setError(error.message)
          }
          finally{
              setLoading(false)
          }
        }
        fetchCourses();
      
      }, [id])

  return (
    <Box className='enrolled-course-card-box'>

            <Card elevation={3} >
                <Link to={`../coursedetailsuser/${course?.courseId}/`}>

                    <CardMedia
                        component='img'
                        height={140}
                        image={`${course?.bannerImg}`}
                        alt='Course'
                        className='enrolled-course-card-image'
                    />
                    <CardContent className='enrolled-course-card-content'>
                        <Typography gutterBottom variant="h5" component='div' color='#1556FC'
                        className='enrolled-course-card-content-title'>
                            {course?.courseTitle}

                        </Typography>
                        <Typography variant="body2" color='black'
                        className='enrolled-course-card-content-topiclist'>
                            {course?.topicList}
                        </Typography>
                    </CardContent>
                </Link>

                <CardActions className='enrolled-course-card-content'>
                    <divreview className='enrolled-course-card-review'>
                        <div1>
                            <div11>{course?.classNumber} class</div11>
                            <div12>|</div12>
                            <div13>{course?.courseDuration} Months</div13>
                        </div1>
                        <div2>
                            {course?.courseRating}
                            <div21>*</div21>
                            <div21>*</div21>
                            <div21>*</div21>
                            <div21>*</div21>
                            <div21>*</div21>
                            (245)
                        </div2>
                    </divreview>
                    {/* <Button size='small'>Share</Button> */}
                    <div className='enrolled-course-card-footer-content'>
                        <div className='enrolled-course-card-footer-content-title'>
                            Course Fee
                        </div>
                        <div className='enrolled-course-card-footer-content-fee'>
                            <div className='enrolled-course-card-footer-content-fee-actual'>
                                {course?.courseFee}
                                <FontAwesomeIcon icon={faBangladeshiTakaSign} />
                            </div>
                            <div className='enrolled-course-card-footer-content-fee-discount'>
                                {course?.courseFee - course?.courseDiscount} -
                                <FontAwesomeIcon icon={faBangladeshiTakaSign} />
                            </div>
                        </div>
                    </div>
                    {/* <div  className='enroll-button'>Enroll</div> */}
                    {userInfo?.enrolledCourses?.includes(course?.courseId) ? (
                                            <Link to={`../coursedetailsuser/${course?.courseId}/`} state={{ course: course }}>
                                                <div className='enrolled-play-button'>Play Course</div>
                                            </Link>
                                        ) : (
                                            <>
                                                <Link to={'../coursecheckout'} state={{ course: course }}>
                                                    <div className='enrolled-enroll-button'>Add to Cart</div>
                                                </Link>
                    
                                            </>
                                        )}

                </CardActions>
            </Card>
        </Box>
  )
}

export default EnrolledCourseCard