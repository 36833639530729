import './App.css';
import React from 'react'
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import { AuthProvider } from './context/AuthContext'
import Home from './routes/Home/Home';
import Blog from './routes/Blog';
import Contact from './routes/Contact';
import Course from './routes/Course/Course';
import FreeCourse from './routes/Freecourse';
import Scholarship from './routes/Scholarship';
import UserDashboard from './routes/UserDashboard/UserDashboard';
import SignUp from './routes/Signup';
import Login from './routes/Login';
import Enroll from './routes/Enroll';
import CourseDetails from './routes/Course/CourseDetails';
import CourseDetailsUser from './routes/Course/CourseDetailsUser';
import { useEffect, useState } from 'react'
import EnrolledCourses from './routes/UserDashboard/EnrolledCourses';
import BlogDetail from './routes/Blog/BlogDetail';
import GoogleCallback from './components/GoogleCallBack';
import CoursePlayed from './routes/Course/CoursePlayed';
import Activate from './components/Activate';
import CheckoutPage from './routes/Course/Checkout';
import Profile from './routes/UserDashboard/Profile';
import PasswordResetRequest from './components/PasswordResetRequest';
import PasswordResetConfirm from './components/PasswordResetConfirm';
import ExamComponent from './components/ExamComponent';
import ExamList from './components/ExamList';
import CartPage from './routes/Course/CartPage';
import SavedForLaterPage from './routes/Course/SavedForLaterPage';

const App = () => {

    return (
        <div className='App'>
            <Router>
                <AuthProvider>
                    <Routes>
                        
                        {/* <Route path='/userdashboard' element={
                            <ProtectedRoute>

                                <UserDashboard />
                            </ProtectedRoute>

                            } /> */}
                            <Route path="/login" element={<Login />} />
                            <Route path="/activate/:uid/:token" element={<Activate />} />
                            <Route path='/forget_pass' element={<PasswordResetRequest />} />            
                            <Route path="/password_reset_confirm/:uid/:token" element={<PasswordResetConfirm />} />
                            {/* <Route path="/register" element={<Register />} /> */}
                            <Route path="/" exact element={<Home />} />
                            <Route path='/' element={<Home />} />
                            <Route path='/blog' element={<Blog />} />
                            <Route path="/blog/:id" element={<BlogDetail />} />
                            <Route path='/contact' element={<Contact />} />
                            <Route path='/course' element={<Course />} />
                            <Route path='/freecourse' element={<FreeCourse />} />
                            <Route path='/scholarship' element={<Scholarship />} />

                        <Route path='/signup' element={<SignUp />} />
                        <Route path='/signup/login' element={<Login />} />
                        <Route path='/userdashboard' element={<UserDashboard />} />
                        <Route path="/google/callback/" element={<GoogleCallback />}/>
                        
                        {/* <Route path='/signup/login/userdashboard/enrolledcourses' element={<EnrolledCourses />} /> */}
                        <Route path='/course/enroll' element={<Enroll />} />
                        <Route path='/course/:id' element={<CourseDetails />} />
                        <Route path='/coursedetailsuser/:id' element={<CoursePlayed />} />
                        <Route path='/coursecheckout' element={<CheckoutPage />} />
                        <Route path='/exam/:id' element={<ExamComponent />} />
                        <Route path='/examlist' element={<ExamList />} />
                        <Route path='/cartpage' element={<CartPage />} />
                        <Route path="/savedforlater" element={<SavedForLaterPage />}/>
                        {/* <Route path='/userprofile/:id' element={<Profile />} /> */}
                    </Routes>
                </AuthProvider>
            </Router>
        </div>
    )
}

export default App