import React, { useState, useContext, useEffect } from 'react';
import './checkout.css';
import Navbar from '../../components/Navbar';
import Footer from '../../components/MyFooter';
import AuthContext from '../../context/AuthContext';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import { createPayment } from '../../services/paymentService';
// import { useBkash } from 'react-bkash';

const CheckoutPage = () => {
  const location = useLocation();
  const { course } = location.state || {};
  console.log('The course info =', course);

  const { authTokens, user, setCart, cart } = useContext(AuthContext);
  const token = authTokens?.access;
  const navigate = useNavigate();
  const [couponCode, setCouponCode] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [selectedBankingProvider, setSelectedBankingProvider] = useState('');

  const handleCouponChange = (e) => setCouponCode(e.target.value);
  const handlePaymentChange = (e) => setPaymentMethod(e.target.value);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!user) {
      Swal.fire({
        title: 'Warning!',
        text: "You are not Logged in",
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
      navigate('/login')
    }

  }, [user])

  // bKash Integration
  // const { error, loading, triggerBkash } = useBkash({
  //   onSuccess: (data) => {
  //     Swal.fire('Success!', 'Payment was successful!', 'success');
  //     console.log('Payment successful:', data);
  //   },
  //   onClose: () => {
  //     Swal.fire('Cancelled', 'bKash payment window closed', 'info');
  //   },
  //   bkashScriptURL:
  //     'https://scripts.sandbox.bka.sh/versions/1.2.0-beta/checkout/bKash-checkout-sandbox.js',
  //   amount: course.courseFee - course.courseDiscount,
  //   onCreatePayment: async (paymentRequest) => {
  //     const response = await fetch('http://127.0.0.1:8000/payments/create-payment/', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(paymentRequest),
  //     });
  //     return await response.json();
  //   },
  //   onExecutePayment: async (paymentID) => {
  //     const response = await fetch(
  //       `http://127.0.0.1:8000/payments/execute-payment/${paymentID}/`,
  //       {
  //         method: 'POST',
  //       }
  //     );
  //     return await response.json();
  //   },
  // });
  // code for create payment into backend 
  const [paymentData, setPaymentData] = useState({
    amount: course.courseFee,
    discount: course.courseDiscount,
    payment: course.courseFee - course.courseDiscount,
    paymentStatus: 1, // Default to Pending (status 1)
    transactionId: Math.random(),
    userId: user?.user_id, // Assuming this is dynamically set based on logged-in user
    courseId: cart // Assuming this is dynamically set based on selected course
  });
  const [loading, setLoading] = useState(false); // To show loading spinner or state
  const [error, setError] = useState(null);
  // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentData({
      ...paymentData,
      [name]: value
    });
  };
  useEffect(() => {
    setPaymentData({
      ...paymentData,
      amount: course.courseFee,
      discount: course.courseDiscount,
      payment: course.courseFee - course.courseDiscount,
      paymentStatus: 1, // Default to Pending (status 1)
      transactionId: Math.random(),
      userId: user?.user_id, // Assuming this is dynamically set based on logged-in user
      courseId: cart
    })


  }, [course])

  // Handle form submission
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   setError(null);
  //   console.log("paymentData",paymentData)
  //   try {
  //     const response = await createPayment(paymentData, token);
  //     setLoading(false);
  //     console.log('Payment created:', response);
  //     Swal.fire({
  //       title: 'Payment Done!',
  //       text: 'You have enrolled the course successfully ',
  //       icon: 'success',
  //       confirmButtonText: 'Ok',
  //     });
  //   clearCart();


  //     // navigate(`../course/${course.courseId}`)
  //     // window.location.assign(`../course/${course.courseId}`);
  //     window.location.assign(`../course/`);
  //   } catch (err) {
  //     setLoading(false);
  //     console.log("failed to enroll ",err)
  //     setError(err.message || 'Something went wrong');
  //     Swal.fire({
  //       title: 'Warning!',
  //       text: 'Failed to Enroll',
  //       icon: 'warning',
  //       confirmButtonText: 'Ok',
  //     });
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    cart.map(async (courseId) => {
      const paymentDataArray = {
        amount: course.courseFee,
        discount: course.courseDiscount,
        payment: course.courseFee - course.courseDiscount,
        paymentStatus: 1, // Default to Pending (status 1)
        transactionId: Math.random(),
        userId: user?.user_id, // Logged-in user
        courseId,
      }
      try {
        const response = await createPayment(paymentDataArray, token);
        setLoading(false);
        console.log('Payments created:', response);

        // Swal.fire({
        //   title: 'Payment Done!',
        //   text: 'You have enrolled in the courses successfully.',
        //   icon: 'success',
        //   confirmButtonText: 'Ok',
        // });

        setCart([])
        window.location.assign(`../course/`);
      } catch (err) {
        setLoading(false);
        console.log('Failed to enroll', err);
        setError(err.message || 'Something went wrong');

        Swal.fire({
          title: 'Warning!',
          text: 'Failed to Enroll',
          icon: 'warning',
          confirmButtonText: 'Ok',
        });
      }
    });


  };

  useEffect(() => {
    if (!user) {
      Swal.fire({
        title: 'Warning!',
        text: 'You are not logged in',
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
      navigate('/login');
    }
  }, [user]);

  return (
    <div>
      <Navbar />
      <div className="checkout-page">
        <header className="header">
          <h1>Complete Your Purchase</h1>
        </header>

        <div className="checkout-container">
          {/* Order Summary */}
          <section className="checkout-order-summary">
            <h2>Order Summary</h2>
            <div className="course-item">


              <p>
                <strong>Original Price:</strong> <span className="amount">৳ {course.courseFee}</span>
              </p>
              <p>
                <strong>Discount:</strong> <span className="amount">৳ {course.courseDiscount}</span>
              </p>
              <p className="total">
                <strong>Total: <span className="amount">৳ {course.courseFee - course.courseDiscount}</span></strong>
              </p>
            </div>
            <div className='order-summary-footer'>
              <input
                type="text"
                className="coupon-input"
                placeholder="Enter coupon code"
                value={couponCode}
                onChange={handleCouponChange}
              />
              <button className="apply-coupon">Apply Coupon</button>
            </div>

          </section>

          {/* Payment Method */}
          <section className="payment-method">
            <h2>Payment Method</h2>
            <div className="payment-options">
              <div
                className={`payment-option-card ${paymentMethod === 'card' ? 'selected' : ''}`}
                onClick={() => setPaymentMethod('card')}
              >
                <i className="fa fa-credit-card" aria-hidden="true"></i>
                <span>Credit/Debit Card</span>
              </div>
              <div
                className={`payment-option-card ${paymentMethod === 'mobile-banking' ? 'selected' : ''}`}
                onClick={() => setPaymentMethod('mobile-banking')}
              >
                <i className="fa fa-mobile" aria-hidden="true"></i>
                <span>Mobile Banking</span>
              </div>
              <div
                className={`payment-option-card ${paymentMethod === 'bkash' ? 'selected' : ''}`}
                onClick={() => setPaymentMethod('bkash')}
              >
                <i className="fa fa-mobile" aria-hidden="true"></i>
                <span>bKash</span>
              </div>
            </div>

            {paymentMethod === 'card' && (
              <div className="card-info">
                <label>Card Number</label>
                <input type="text" placeholder="1234 5678 9012 3456" />
                <label>Expiration Date</label>
                <input type="text" placeholder="MM/YY" />
                <label>CVV</label>
                <input type="text" placeholder="123" />
              </div>
            )}
            {paymentMethod === 'mobile-banking' && (
              <div className="mobile-banking-options">
                <label>Choose Mobile Banking Provider</label>
                <select onChange={(e) => setSelectedBankingProvider(e.target.value)}>
                  <option value="">Select Provider</option>
                  <option value="bkash">bKash</option>
                  <option value="nagad">Nagad</option>
                  <option value="rocket">Rocket</option>
                  <option value="upay">Upay</option>
                </select>
                {selectedBankingProvider && (
                  <>
                    <input type="text" placeholder="Enter Account Number" />
                    <input type="text" placeholder="Enter OTP" />
                    <input type="password" placeholder="Payment Pin" />
                  </>
                )}
              </div>
            )}
            {paymentMethod === 'bkash' && (
              <div className="bkash-payment">
                <button className="place-order">
                  Proceed with bKash
                </button>
              </div>
            )}
          </section>

          {/* Review & Confirm */}
          <section className="review-confirm">
            <h2>Review & Confirm</h2>
            <p className="total-amount">
              Total Amount: <span>৳ {course.courseFee - course.courseDiscount}</span>
            </p>
            <div className="terms">
              <input type="checkbox" id="terms" />
              <label htmlFor="terms">
                I agree to the <a href="#terms">terms and conditions</a>
              </label>
            </div>
            <button className="place-order" onClick={handleSubmit}>Place Order</button>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CheckoutPage;
