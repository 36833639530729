import React, { useState, useEffect, useContext } from 'react';
import config from '../config';
import AuthContext from '../context/AuthContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Navbar from './Navbar';
import './ExamComponent.css'
import { Button } from '@mui/material';
import Swal from 'sweetalert2';

const ExamComponent = () => {
  let params = useParams();
  const examId = params?.id;
  const { user, authTokens, userInfo } = useContext(AuthContext)
  const token = authTokens?.access;
  // console.log("token",token)
  const [exam, setExam] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [attemptId, setAttemptId] = useState(null);
  const [examResult, setExamResult] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(10);
  const [error, setError] = useState(null);
  const navigate = useNavigate()
  // Start exam process


  useEffect(() => {
    const startExam = async () => {
      try {
        const response = await fetch(`${config.apiUrl}exams/${examId}/start/`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        console.log("response", response)
        if (!response.ok) {
          throw new Error('Failed to start exam');
        }

        const data = await response.json();
        console.log("data", data);
        setExam(data?.exam);
        setAttemptId(data?.attempt_id);
        setTimeRemaining(data?.exam.duration_minutes * 60);
      } catch (error) {
        setError(error.message);
        console.error('Error starting exam', error);
      }
    };
    startExam();
  }, [examId, token]);

  useEffect(() => {
    if (!user) {
      Swal.fire({
        title: 'Warning!',
        text: 'Please Login First',
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
      navigate('../login');
    }

    console.log("trying ", !userInfo?.enrolledCourses?.includes(exam?.courseId));
    if (exam && !userInfo?.enrolledCourses.includes(exam.courseId)) {
      Swal.fire({
        title: 'Warning!',
        text: 'Please Enroll First',
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
      navigate('../course');
    }


  }, [exam, examId])

  // Timer countdown
  useEffect(() => {
    if (timeRemaining > 0 && !examResult) {
      const timer = setTimeout(() => {
        setTimeRemaining(prev => prev - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (timeRemaining === 0) {
      submitExam();
      console.log("time over")
    }
  }, [timeRemaining]);

  const handleAnswerSelect = (questionId, choiceId) => {
    setSelectedAnswers(prev => ({
      ...prev,
      [questionId]: choiceId
    }));
  };

  const submitExam = async () => {
    try {
      const answers = Object.entries(selectedAnswers).map(([questionId, choiceId]) => ({
        question_id: parseInt(questionId),
        choice_id: choiceId
      }));

      const response = await fetch(`${config.apiUrl}exams/${examId}/submit/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          attempt_id: attemptId,
          answers: answers
        })
      });

      if (!response.ok) {
        throw new Error('Failed to submit exam');
      }

      const data = await response.json();
      setExamResult(data);
    } catch (error) {
      setError(error.message);
      console.error('Error submitting exam', error);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  if (error) {
    return (
      <div className="text-red-500 p-4 border border-red-500 rounded">
        Error: {error}
      </div>
    );
  }

  if (examResult) {
    return (
      <>
        <Navbar />
        <div className="max-w-md mx-auto p-4 border rounded shadow-lg pt-40">
          <h2 className="text-2xl font-bold mb-4 text-center">Exam Results</h2>
          <div className="text-center">
            <p className="text-xl">Total Obtained Score: <p className={`text-4xl ${examResult.is_passed ? 'text-green-600' : 'text-red-600'}`}> {examResult.total_score.toFixed(2)} </p> </p>
            <p className={`font-bold ${examResult.is_passed ? 'text-green-600' : 'text-red-600'}`}>
              Status: {examResult.is_passed ? 'Passed' : 'Failed'}
            </p>

          </div>
          <div className='flex justify-between items-center'>

            
              <Button variant='outlined' onClick={()=>window.location.reload()}>
                Start Again
              </Button>
            
           
            <Link to={`../coursedetailsuser/${exam.courseId}`}>
              <Button variant='outlined' color="secondary" className='px-4 m-4'>
                Back to Courses
              </Button>
            </Link>

          </div>

        </div>
      </>
    );
  }

  if (!exam) return <div>Loading exam...</div>;

  const currentQuestion = exam?.questions[currentQuestionIndex];

  return (<>
    <Navbar />
    <div className="exam-page  ">
      <div className="exam-header mt-20">
        <h1 className="text-2xl font-bold">{exam?.title}</h1>
        <div className="timer">
          Time Remaining: {formatTime(timeRemaining)}
        </div>
      </div>

      <div className="question-section">
        <h2 className="text-xl font-semibold mb-4">{currentQuestion?.text}</h2>

        <div className="space-y-2 options">
          {currentQuestion?.choices.map(choice => (
            <div key={choice.id} className="flex items-center">
              <input
                type="radio"
                id={`choice-${choice.id}`}
                name={`question-${currentQuestion?.id}`}
                value={choice.id}
                checked={selectedAnswers[currentQuestion?.id] === choice.id}
                onChange={() => handleAnswerSelect(currentQuestion?.id, choice.id)}
                className="mr-2 peer h-5 w-5 cursor-pointer appearance-none rounded-full border border-slate-300 checked:bg-green-500 transition-all"
              />
              <label className='option' htmlFor={`choice-${choice.id}`}>{choice.text}</label>
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-between">
        {currentQuestionIndex > 0 && (
          <Button
            onClick={() => setCurrentQuestionIndex(prev => prev - 1)}
            className="px-4 py-2 border rounded hover:bg-gray-100"
            variant="contained" color="info"
          >
            Previous
          </Button>
        )}

        {currentQuestionIndex < exam.questions.length - 1 ? (
          <Button
            onClick={() => setCurrentQuestionIndex(prev => prev + 1)}
            disabled={!selectedAnswers[currentQuestion.id]}
            className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
            variant="contained" color="primary"
          >
            Next
          </Button>
        ) : (
          <Button
            onClick={submitExam}
            disabled={Object.keys(selectedAnswers).length !== exam.questions.length}
            className="submit-button"
            variant="contained" color="success"
          >
            Submit Exam
          </Button>
        )}
      </div>
    </div>
  </>
  );
};

export default ExamComponent;